.form-container {
	margin-top:20px;
	margin-bottom:10px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.form-container form {
	max-width:900px;
}
.form-container .cell {
	margin:0 20px 20px 0;
}
.form-container .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.form-container input, .form-container select, .form-container textarea {
	display:block;
	width:215px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
	resize:none;
}
.form-container input:focus, .form-container select:focus, .form-container textarea:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.form-container textarea {
	width:685px;
}
.examples {
	width:800px;
	margin:20px 0;
	display:flex;
	align-items:flex-start;
	flex-direction:row;
	justify-content:space-between;
}
.examples .examples-ios, .examples .examples-android {
	width:385px;
	padding:10px;
	border:1px solid #ccc;
	background:#fff;
	overflow-y:hidden;
}
.examples .examples-ios {
	margin-right:20px;
	box-shadow: rgb(212 212 213) 0 1px 3px 0px;
	border-radius:10px;
}
.examples .examples-android {
	box-shadow: rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 20%) 0 3px 1px -2px, rgb(0 0 0 / 12%) 0 1px 5px 0;
}
.examples .examples-header {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-bottom:10px;
}
.examples .examples-ios .examples-header span {
	display:block;
	width:20px;
	height:20px;
	background:url(./images/icon-ios.svg) 50% 50% no-repeat;
	margin-right:8px;
}
.examples .examples-ios .examples-header {
	text-transform:uppercase;
}
.examples .examples-android .examples-header span {
	display:block;
	width:20px;
	height:20px;
	background:url(./images/icon-android.svg) 50% 50% no-repeat;
	margin-right:8px;
}
.examples .examples-title {
	font-weight:600;
}
.examples .examples-subtitle {
	font-weight:600;
}
.examples .examples-text {
	margin-top:5px;
}

@media (max-width: 768px) {
	.examples {
		flex-direction: column;
    	width: 100%;
	}
	.examples .examples-ios, 
	.examples .examples-android {
		width: 100%;
		margin: 0;
	}
	.examples .examples-ios {
		margin-bottom: 20px;
	}

	.form-container input, .form-container select, .form-container textarea {
		width: 100%;
	}

	.form-container .cell {
		margin: 0 0 20px;
	}
}